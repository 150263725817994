import React, {useEffect, useState} from 'react'
import TitreRapport from './titre_rapport'
import GraphAleasRapport from './graphAleas_Rapport.js'
import GraphAleasNBjoursRapport from './graphAleasNbjours_Rapport.js'
import GraphAleasNBjoursRapport2 from './graphAleasNbjours_Rapport2.js'
import apiLink from '../../config.js'

function PageAleasPDF({idExploit}) {
  const [ExploitMixte, setExploitMixte] = useState(false)
  const [ExploitViti, setExploitViti] = useState(false)
  const [ExploitGC,setExploitGC] = useState(true)

  const [dataMoy_SH_2D, setDataMoy_SH_2D] =  React.useState()
  const [dataMoy_SH_4D, setDataMoy_SH_4D] =  React.useState()
  const [dataMin_SH_2D, setDataMin_SH_2D] =  React.useState()
  const [dataMin_SH_4D, setDataMin_SH_4D] =  React.useState()
  const [dataMax_SH_2D, setDataMax_SH_2D] =  React.useState()
  const [dataMax_SH_4D, setDataMax_SH_4D] =  React.useState()
  const [dataQ1_SH_2D, setDataQ1_SH_2D] =  React.useState()
  const [dataQ1_SH_4D, setDataQ1_SH_4D] =  React.useState()
  const [dataQ3_SH_2D, setDataQ3_SH_2D] =  React.useState()
  const [dataQ3_SH_4D, setDataQ3_SH_4D] =  React.useState()
  const [dataMoy_EE_2D, setDataMoy_EE_2D] =  React.useState()
  const [dataMoy_EE_4D, setDataMoy_EE_4D] =  React.useState()
  const [dataMin_EE_2D, setDataMin_EE_2D] =  React.useState()
  const [dataMin_EE_4D, setDataMin_EE_4D] =  React.useState()
  const [dataMax_EE_2D, setDataMax_EE_2D] =  React.useState()
  const [dataMax_EE_4D, setDataMax_EE_4D] =  React.useState()
  const [dataQ1_EE_2D, setDataQ1_EE_2D] =  React.useState()
  const [dataQ1_EE_4D, setDataQ1_EE_4D] =  React.useState()
  const [dataQ3_EE_2D, setDataQ3_EE_2D] =  React.useState()
  const [dataQ3_EE_4D, setDataQ3_EE_4D] =  React.useState()
  const [dataNbjours_SH_2D, setdataNbjours_SH_2D] = React.useState()
  const [dataNbjours_SH_4D, setdataNbjours_SH_4D] = React.useState()
  const [dataNbjours_EE_2D, setdataNbjours_EE_2D] = React.useState()
  const [dataNbjours_EE_4D, setdataNbjours_EE_4D] = React.useState()

  function fetchData() {
    fetch(apiLink + 'page/production-rapport/' + idExploit)
    .then( (response) => {
    return response.json()
    })
    .then(response =>{
    let data = response
    for (var cle in data) {
        if (cle.includes("Vignes")) {
            setExploitMixte(true)
            setExploitGC(false)
            setExploitViti(false);
            break;
        }
    }
    let allKeysContainVignes = Object.keys(data).every(cle => cle.includes("Vignes"));
    if (allKeysContainVignes) {
        setExploitViti(true);
        setExploitMixte(false)
        setExploitGC(false)
    } else {
        setExploitViti(false);
    }
    })

    fetch('https://api2050.diagorisk.com/pageAleas/?idExploitation=' + idExploit)
      .then((response) => response.json())
      .then((response) => {
        if ('error' in response) {
          // Si la réponse contient une erreur, relancer la requête après 60 secondes
          setTimeout(fetchData, 60000);
        } else {
          // Si la réponse est réussie, mettre à jour les données
          setDataMoy_SH_2D(response.dataMoy_SH_2D)
          setDataMoy_SH_2D(prev => {
            return prev;
          })
          setDataMoy_SH_4D(response.dataMoy_SH_4D)
          setDataMoy_SH_4D(prev => {
            return prev;
          })
          setDataMin_SH_2D(response.dataMin_SH_2D)
          setDataMin_SH_2D(prev => {
            return prev;
          })
          setDataMin_SH_4D(response.dataMin_SH_4D)
          setDataMin_SH_4D(prev => {
            return prev;
          })
          setDataMax_SH_2D(response.dataMax_SH_2D)
          setDataMax_SH_2D(prev => {
            return prev;
          })
          setDataMax_SH_4D(response.dataMax_SH_4D)
          setDataMax_SH_4D(prev => {
            return prev;
          })
          setDataQ1_SH_2D(response.dataQ1_SH_2D)
          setDataQ1_SH_2D(prev => {
            return prev;
          })
          setDataQ1_SH_4D(response.dataQ1_SH_4D)
          setDataQ1_SH_4D(prev => {
            return prev;
          })
          setDataQ3_SH_2D(response.dataQ3_SH_2D)
          setDataQ3_SH_2D(prev => {
            return prev;
          })
          setDataQ3_SH_4D(response.dataQ3_SH_4D)
          setDataQ3_SH_4D(prev => {
            return prev;
          })
          setDataMoy_EE_2D(response.dataMoy_EE_2D)
          setDataMoy_EE_2D(prev => {
            return prev;
          })
          setDataMoy_EE_4D(response.dataMoy_EE_4D)
          setDataMoy_EE_4D(prev => {
            return prev;
          })
          setDataMin_EE_2D(response.dataMin_EE_2D)
          setDataMin_EE_2D(prev => {
            return prev;
          })
          setDataMin_EE_4D(response.dataMin_EE_4D)
          setDataMin_EE_4D(prev => {
            return prev;
          })
          setDataMax_EE_2D(response.dataMax_EE_2D)
          setDataMax_EE_2D(prev => {
            return prev;
          })
          setDataMax_EE_4D(response.dataMax_EE_4D)
          setDataMax_EE_4D(prev => {
            return prev;
          })
          setDataQ1_EE_2D(response.dataQ1_EE_2D)
          setDataQ1_EE_2D(prev => {
            return prev;
          })
          setDataQ1_EE_4D(response.dataQ1_EE_4D)
          setDataQ1_EE_4D(prev => {
            return prev;
          })
          setDataQ3_EE_2D(response.dataQ3_EE_2D)
          setDataQ3_EE_2D(prev => {
            return prev;
          })
          setDataQ3_EE_4D(response.dataQ3_EE_4D)
          setDataQ3_EE_4D(prev => {
            return prev;
          })
          setdataNbjours_SH_2D(response.dataNbjours_SH_2D)
          setdataNbjours_SH_2D(prev => {
            return prev;
          })
          setdataNbjours_SH_4D(response.dataNbjours_SH_4D)
          setdataNbjours_SH_4D(prev => {
            return prev;
          })
          setdataNbjours_EE_2D(response.dataNbjours_EE_2D)
          setdataNbjours_EE_2D(prev => {
            return prev;
          })
          setdataNbjours_EE_4D(response.dataNbjours_EE_4D)
          setdataNbjours_EE_4D(prev => {
            return prev;
          })
        }
      })
      .catch((error) => {
        // Gérer les erreurs de la requête
        console.error('Erreur lors de la requête API :', error);
      });
  }
  useEffect ( () => {
    fetchData()
  }, [idExploit])
  
  return (
    <div className='containerPrint'>
      <TitreRapport Titre={'Impact des aléas climatique'} Sous_Titre={"Sur toute l'exploitation"} Page={ExploitViti ? '0' : '5'}/>
      <div className='paragraphe'>Les impacts en fonction de chaque aléas est pour chaque année de 2020 à 2050 en moyenne 10 ans.</div>
      <div className='paragraphe'>Les impacts données sont pour l'ensemble de votre exploitation.</div>
      <div className='titreMoins row' style={{marginBottom:'20px', marginTop:'40px'}}> Stress hydrique</div>
      <div className='row'>
        <div style={{width:'50%'}}><GraphAleasRapport myDataMoy2D={dataMoy_SH_2D} myDataMax2D={dataMax_SH_2D} myDataMin2D={dataMin_SH_2D} myDataQ12D={dataQ1_SH_2D} myDataQ32D={dataQ3_SH_2D} myDataMoy4D={dataMoy_SH_4D} myDataMax4D={dataMax_SH_4D} myDataMin4D={dataMin_SH_4D} myDataQ14D={dataQ1_SH_4D} myDataQ34D={dataQ3_SH_4D} aleas={'SH'} print={true}/></div>
        <div style={{width:'50%'}}>{dataNbjours_SH_2D !== undefined && dataNbjours_SH_4D !== undefined  ?<GraphAleasNBjoursRapport2 myDatas2D={dataNbjours_SH_2D} myDatas4D={dataNbjours_SH_4D} print={true}/> : ""} </div>
      </div>
      
      <div className='titreMoins row' style={{marginBottom:'20px', marginTop:'40px'}}> Excès d'eau</div>
      <div className='row'>
        <div style={{width:'50%'}}><GraphAleasRapport myDataMoy2D={dataMoy_EE_2D} myDataMax2D={dataMax_EE_2D} myDataMin2D={dataMin_EE_2D} myDataQ12D={dataQ1_EE_2D} myDataQ32D={dataQ3_EE_2D} myDataMoy4D={dataMoy_EE_4D} myDataMax4D={dataMax_EE_4D} myDataMin4D={dataMin_EE_4D} myDataQ14D={dataQ1_EE_4D} myDataQ34D={dataQ3_EE_4D} aleas={'EE'} print={true}/></div>
        <div style={{width:'50%'}}>{dataNbjours_EE_2D !== undefined && dataNbjours_EE_4D !== undefined  ? <GraphAleasNBjoursRapport2  myDatas2D={dataNbjours_EE_2D} myDatas4D={dataNbjours_EE_4D} print={true}/> : ""} </div>
      </div>
    </div>
  )
}

export default PageAleasPDF